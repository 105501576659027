import AnalyticsService from './AnalyticsService';

const analyticsService = new AnalyticsService();

// Main tracking function
export const trackEvent = (eventName, properties = {}) => {
  const language = 'en';

  const eventData = {
    event: eventName,
    platform: 'web',
    accept_language: language,
    online_status: navigator.onLine,
    ...properties
  };

  analyticsService.trackEvent(eventName, eventData);
};

export default analyticsService;