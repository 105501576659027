import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import AllRoutes from "./Routes/Routes";
import "./App.css";
import { checkToken } from "./Utils/axiosIntercepters";
import { loadLinks } from "./Utils/LoadScripts";
import AllFonts from "./assets/fonts";
import ReactGA from "react-ga";
import { initializeAnalytics } from "./Lib/Analytics/init";

ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_ID);
initializeAnalytics()

const App = () => {
  const dispatch = useDispatch();
  // for checking response of all of the request made using axios library
  checkToken({ dispatch });
  // ------
  // for loading the default fonts
  loadLinks({
    srcUrl: AllFonts.Poppins,
  });

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);
  // ----
  return <AllRoutes />;
};

export default App;
