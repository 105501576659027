class BaseAnalyticsProvider {
  constructor(name) {
    this.name = name;
  }

  initialize() {
    throw new Error('Initialize method must be implemented');
  }

  trackEvent(eventName, eventData) {
    throw new Error('TrackEvent method must be implemented');
  }

  formatEventData(eventData) {
    throw new Error('FormatEventData method must be implemented');
  }
}

export default BaseAnalyticsProvider;