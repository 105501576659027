class AnalyticsService {
  constructor() {
    this.providers = new Map();
    this.defaultProvider = null;
  }

  registerProvider(provider, isDefault = false) {
    provider.initialize();
    this.providers.set(provider.name, provider);
    
    if (isDefault) {
      this.defaultProvider = provider;
    }
  }

  trackEvent(eventName, eventData, providerNames = []) {
    // If no specific providers are specified, use all registered providers
    const targetProviders = providerNames.length > 0 
      ? providerNames.map(name => this.providers.get(name)).filter(Boolean)
      : [this.defaultProvider];

    targetProviders.forEach(provider => {
      try {
        provider.trackEvent(eventName, eventData);
      } catch (error) {
        console.error(`Error tracking event with ${provider.name}:`, error);
      }
    });
  }
}

export default AnalyticsService;