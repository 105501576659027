import mixpanel from 'mixpanel-browser';
import BaseAnalyticsProvider from './BaseProvider';

class MixpanelProvider extends BaseAnalyticsProvider {
  constructor(apiKey) {
    super('mixpanel');
    this.apiKey = apiKey;
  }

  initialize() {
    mixpanel.init(this.apiKey);
    this.instance = mixpanel;
  }

  trackEvent(eventName, eventData) {
    const formattedData = this.formatEventData(eventData);
    this.instance.track(eventName, formattedData);
  }

  formatEventData(eventData) {
    const formattedData = { ...eventData };
    
    // Format dynamic attributes (attr1 through attr12)
    for (let i = 1; i <= 12; i++) {
      const attr = eventData[`attr${i}`];
      if (attr?.type) {
        formattedData[attr.type] = attr.value || '';
        delete formattedData[`attr${i}`];
      }
    }

    return formattedData;
  }
}

export default MixpanelProvider;